export const queryVars = {
	page: "page",
	page_size: "page-size",
	ordering: "ordering",
	wallet_type: "wallet_type",
	is_favorite: "is_favorite",
	type: "type",
	search: "search",
	sub_account: "sub_account",
	account: "account",
	date: "date",
	date_after: "date_after",
	date_before: "date_before",
	desc: "desc",
	asc: "asc",
	pathname: "pathname",
	sort: "sort",
	layout: "layout",
	currency: "currency",
	pair: "pair",
	amount: "amount",
	direction: "direction",
	is_redeemed: "is_redeemed",
	percent_change_24h: "percent_change_24h",
	value: "value",
	redeemed_at: "redeemed_at",
	subscribed_at: "subscribed_at",
	side: "side",
	currency_id: "currency_id",
	is_outgoing: "is_outgoing",
	is_outdated: "is_outdated",
	status: "status",
	is_active: "is_active",
	order_id: "order_id",
	payment_type_name: "payment_type_name",
	email: "email",
	curr: "curr", // TODO: Rename it on back to "currency"
	out: "out",
	in: "in",
	aff_sub: "aff_sub",
	aff_id: "aff_id",
	utm_campaign: "utm_campaign",
	vote: "vote",
	category: "category",
	position_id: "position_id",
} as const;
