import React from "react";
import Footer from "components/Footer";
import Header from "components/Header";
import Cookies from "components/Cookies";

interface IProps {
	className?: string;
}

const LandingLayout: React.FC<IProps> = ({ children, className }) => (
	<>
		<Header isLanding className={className} />
		{children}
		<Footer isLanding className={className} />
		<Cookies />
	</>
);

export default LandingLayout;
