import React, { useCallback, useState } from "react";
import styles from "styles/components/UI/Accordion.module.scss";
import cn from "classnames";
import AccordionRow from "./AccordionRow";

export interface ISection {
	icon?: string;
	label: string;
	value: string | ISection[] | JSX.Element;
	link?: string;
}

interface Props {
	className?: string;
	classNameRow?: string;
	classNameContent?: string;
	sections: ISection[];
	plain?: boolean;
}

const Accordion: React.FC<Props> = React.memo(
	({ className, classNameRow, classNameContent, sections, plain }) => {
		const [activeRow, setActiveRow] = useState<number | null>(null);

		const handleRowToggle = useCallback(
			(idx: number) => {
				setActiveRow(activeRow === idx ? null : idx);
			},
			[activeRow],
		);

		return (
			<div className={cn(styles.list, className)}>
				{sections.map((s: ISection, idx: number) => (
					<AccordionRow
						className={classNameRow}
						classNameContent={classNameContent}
						key={idx}
						plain={plain}
						section={s}
						toggle={() => handleRowToggle(idx)}
						collapsed={idx !== activeRow}
					/>
				))}
			</div>
		);
	},
);

export default Accordion;
