import { defineMessages } from "react-intl";

export default defineMessages({
	page_title: {
		id: "app.components.aml_report.page_title",
		defaultMessage: "Digital assets tracking and AML Compliance check",
	},
	page_subtitle: {
		id: "app.components.aml_report.page_subtitle",
		defaultMessage:
			"Here you can request a check to see if the address has been involved in money laundering operations",
	},
	check: {
		id: "app.components.aml_report.check",
		defaultMessage: "Check",
	},
	check_fee: {
		id: "app.components.aml_report.check_fee",
		defaultMessage: "Check fee",
	},
	your_balance: {
		id: "app.components.aml_report.your_balance",
		defaultMessage: "Your balance",
	},
	report: {
		id: "app.components.aml_report.report",
		defaultMessage: "Report",
	},
	search_input_placeholder: {
		id: "app.components.aml_report.search_input_placeholder",
		defaultMessage: "Enter the address or tx hash",
	},
	login: {
		id: "app.components.aml_report.login",
		defaultMessage: "Enter the address or tx hash",
	},
	please_login_to_coplete_check: {
		id: "app.components.aml_report.please_login_to_coplete_check",
		defaultMessage: "Please, login to complete check",
	},
	type_address_or_transaction_hash: {
		id: "app.components.aml_report.type_address_or_transaction_hash",
		defaultMessage:
			"Type address or transaction hash in the search bar above to get source of funds report",
	},
	click_here_to_load_demo_report: {
		id: "app.components.aml_report.click_here_to_load_demo_report",
		defaultMessage: "Click here to load demo report",
	},
	completed_checks: {
		id: "app.components.aml_report.completed_checks",
		defaultMessage: "Completed checks",
	},
	this_wallet_address_is_being_monitored: {
		id: "app.components.aml_report.this_wallet_address_is_being_monitored",
		defaultMessage:
			"This wallet address is being monitored, you are able to receive fast and detailed reports as well as alerts in case of new incoming transactions, new counterparties, or AML/CTF risks identified. This report was generated by using the last 1000 incoming transactions of the requested address.",
	},
	this_report_was_generated_by_using: {
		id: "app.components.aml_report.this_report_was_generated_by_using",
		defaultMessage:
			"This report was generated by using the last 10 incoming transactions of the requested address. Add the address to the Monitoring to get a report based on the last 1000 incoming transactions",
	},
	there_are_no_transactions_for_this_address: {
		id: "app.components.aml_report.there_are_no_transactions_for_this_address",
		defaultMessage: "There are no transactions for this address",
	},
	wallet_address: {
		id: "app.components.aml_report.wallet_address",
		defaultMessage: "Wallet address",
	},
	owner: {
		id: "app.components.aml_report.owner",
		defaultMessage: "Owner",
	},
	total_sent: {
		id: "app.components.aml_report.total_sent",
		defaultMessage: "Total sent",
	},
	total_tx_sent: {
		id: "app.components.aml_report.total_tx_sent",
		defaultMessage: "Total tx sent",
	},
	first_seen: {
		id: "app.components.aml_report.first_seen",
		defaultMessage: "First seen",
	},
	number_of_txs: {
		id: "app.components.aml_report.number_of_txs",
		defaultMessage: "Number of txs",
	},
	total_received: {
		id: "app.components.aml_report.total_received",
		defaultMessage: "Total received",
	},
	reports_table_address: {
		id: "app.components.aml_report.reports_table_address",
		defaultMessage: "Address/Transaction",
	},
	reports_table_created_at: {
		id: "app.components.aml_report.reports_table_created_at",
		defaultMessage: "Created At",
	},
	reports_table_type: {
		id: "app.components.aml_report.reports_table_type",
		defaultMessage: "Type",
	},
	reports_table_type_address: {
		id: "app.components.aml_report.reports_table_type_address",
		defaultMessage: "Address",
	},
	reports_table_type_address_info: {
		id: "app.components.aml_report.reports_table_type_address_info",
		defaultMessage: "Address Info",
	},
	reports_table_type_address_score: {
		id: "app.components.aml_report.reports_table_type_address_score",
		defaultMessage: "Address Score",
	},
	reports_table_transaction: {
		id: "app.components.aml_report.reports_table_transaction",
		defaultMessage: "Transaction",
	},
	reports_table_transaction_aml_info: {
		id: "app.components.aml_report.reports_table_transaction_aml_info",
		defaultMessage: "Transaction AML Info",
	},
	reports_table_transaction_info: {
		id: "app.components.aml_report.reports_table_transaction_info",
		defaultMessage: "Transaction Info",
	},
	reports_table_transaction_score: {
		id: "app.components.aml_report.reports_table_transaction_score",
		defaultMessage: "Transaction Score",
	},
	view_report: {
		id: "app.components.aml_report.view_report",
		defaultMessage: "View Report",
	},
	no_info: {
		id: "app.components.aml_report.no_info",
		defaultMessage: "There is no info related to this resource",
	},
	cached: {
		id: "app.components.aml_report.cached",
		defaultMessage:
			"Note that the report is actual at the time of creation {date}. Please perform check again to see the current report.",
	},
	address_report_header: {
		id: "app.components.aml_report.address_report_header",
		defaultMessage: "Address information",
	},
	monitoring_btn: {
		id: "app.components.aml_report.monitoring_btn",
		defaultMessage: "Monitoring",
	},
	tags: {
		id: "app.components.aml_report.tags",
		defaultMessage: "Tags",
	},
	balance: {
		id: "app.components.aml_report.balance",
		defaultMessage: "balance",
	},
	total_tx_received: {
		id: "app.components.aml_report.total_tx_received",
		defaultMessage: "Total tx received",
	},
	last_seen: {
		id: "app.components.aml_report.last_seen",
		defaultMessage: "Last seen",
	},
	risk_score: {
		id: "app.components.aml_report.risk_score",
		defaultMessage: "Risk score",
	},
	no_risky_sources: {
		id: "app.components.aml_report.no_risky_sources",
		defaultMessage: "No risky sources were found",
	},
	risk_percent: {
		id: "app.components.aml_report.risk_percent",
		defaultMessage: "{riskPercent} of funds comes from risky sources",
	},
	risk_detected: {
		id: "app.components.aml_report.risk_detected",
		defaultMessage: "AML RISK DETECTED",
	},
	high_risk_entity: {
		id: "app.components.aml_report.high_risk_entity",
		defaultMessage: "Address is owned by a high risk entity",
	},
	participated_in_mixing: {
		id: "app.components.aml_report.participated_in_mixing",
		defaultMessage: "Address has directly participated in mixing",
	},
	address_received_from_risky_sources: {
		id: "app.components.aml_report.address_received_from_risky_sources",
		defaultMessage: "Address received {riskPercent} funds from risky sources",
	},
	address_unidentified_sources: {
		id: "app.components.aml_report.address_unidentified_sources",
		defaultMessage: "More than 75% of sources for the address are unidentified",
	},
	high_risk_activities: {
		id: "app.components.aml_report.high_risk_activities",
		defaultMessage: "Address has directly participated in high risk activities",
	},
	pie_title_by_type: {
		id: "app.components.aml_report.pie_title_by_type",
		defaultMessage: "By Type",
	},
	pie_title_by_owner: {
		id: "app.components.aml_report.pie_title_by_owner",
		defaultMessage: "By Owner",
	},
	transaction_report_header: {
		id: "app.components.aml_report.transaction_report_header",
		defaultMessage: "Transaction information",
	},
	there_are_no_transactions_for_this_hash: {
		id: "app.components.aml_report.there_are_no_transactions_for_this_hash",
		defaultMessage: "There are not transaction for this hash",
	},
	tx_hash: {
		id: "app.components.aml_report.tx_hash",
		defaultMessage: "TX Hash",
	},
	total_amount: {
		id: "app.components.aml_report.total_amount",
		defaultMessage: "total amount",
	},
	block: {
		id: "app.components.aml_report.block",
		defaultMessage: "block",
	},
	total_inputs: {
		id: "app.components.aml_report.total_inputs",
		defaultMessage: "Total inputs",
	},
	total_outputs: {
		id: "app.components.aml_report.total_outputs",
		defaultMessage: "Total outputs",
	},
	timestamp: {
		id: "app.components.aml_report.timestamp",
		defaultMessage: "TIMESTAMP",
	},
	transaction_received_from_risky_sources: {
		id: "app.components.aml_report.transaction_received_from_risky_sources",
		defaultMessage: "{riskPercent} of funds in this transaction are from risky sources",
	},
	transaction_unidentified_sources: {
		id: "app.components.aml_report.transaction_unidentified_sources",
		defaultMessage: "More than 75% of sources for this transaction are unidentified",
	},
	sources_of_funds: {
		id: "app.components.aml_report.sources_of_funds",
		defaultMessage: "Sources of Funds",
	},
	risky_sources: {
		id: "app.components.aml_report.risky_sources",
		defaultMessage: "Risky Sources",
	},
	unknown_sources: {
		id: "app.components.aml_report.unknown_sources",
		defaultMessage: "Unknown Sources",
	},
	known_sources: {
		id: "app.components.aml_report.known_sources",
		defaultMessage: "Known Sources",
	},
	export_to_csv: {
		id: "app.components.aml_report.export_to_csv",
		defaultMessage: "Export to csv",
	},
	export_to_pdf: {
		id: "app.components.aml_report.export_to_pdf",
		defaultMessage: "Export to pdf",
	},
	search_error: {
		id: "app.components.aml_report.search_error",
		defaultMessage: "Search value is not valid",
	},
	multiple: {
		id: "app.components.aml_report.multiple",
		defaultMessage: "Multiple",
	},
	direct_tx: {
		id: "app.components.aml_report.direct_tx",
		defaultMessage: "Direct TX",
	},
	address_cluster_id: {
		id: "app.components.aml_report.address_cluster_id",
		defaultMessage: "Address / Cluster ID",
	},
	type_tag: {
		id: "app.components.aml_report.type_tag",
		defaultMessage: "Type / Tag",
	},
	depth: {
		id: "app.components.aml_report.depth",
		defaultMessage: "Depth",
	},
	share: {
		id: "app.components.aml_report.share",
		defaultMessage: "Share",
	},
	amount: {
		id: "app.components.aml_report.amount",
		defaultMessage: "Amount",
	},
});
