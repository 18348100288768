export enum PageNameEnum {
	Trade = "trade",
	Staking = "staking",
	Competitions = "competitions",
	Demo = "demo",
	Stories = "stories",
	Halving = "halving",
	BuyCrypto = "buy-crypto",
	TradingFees = "trading-fees",
	AlpCoin = "alp-coin",
	Support = "support",
	News = "news",
	NewsCrypto = "news-alpcom",
	SocialListing = "social-listing",
	Listing = "listing",
	ActiveOrders = "active-orders",
	ClosedOrders = "closed-orders",
	TradesHistory = "trades-history",
	Borrows = "borrows",
	Repayments = "repayments",
	Interests = "interests",
	Transfers = "transfers",
	MarginCalls = "margin-calls",
	Liquidations = "liquidations",
	Notifications = "notifications",
	Wallets = "wallets",
	Verification = "verification",
	Referrals = "referrals",
	SubAccounts = "sub-account",
	Settings = "settings",
	Security = "security",
	Api = "api",
	AlphaCode = "alpha-code",
	History = "history",
	Balance = "balance",
	OrderManagement = "order-management",
	AccountManagement = "account-management",
	ApiManagement = "api-management",
	TransferHistory = "transfer-history",
	LoginHistory = "login-history",
	Orders = "orders",
	AML = "aml",
}

export const MENU_DIVIDER = "menu_divider";

export const PROFILE_MENU_DESKTOP_ORDER = [
	PageNameEnum.Verification,
	PageNameEnum.Referrals,
	PageNameEnum.SubAccounts,
	PageNameEnum.Settings,
	PageNameEnum.Security,
	PageNameEnum.Api,
	MENU_DIVIDER,
	PageNameEnum.ActiveOrders,
	PageNameEnum.ClosedOrders,
	PageNameEnum.AlphaCode,
];

export const PROFILE_MENU_SIDEBAR_ORDER = [
	PageNameEnum.Notifications,
	PageNameEnum.Wallets,
	PageNameEnum.Verification,
	PageNameEnum.AlphaCode,
	PageNameEnum.Transfers,
	PageNameEnum.Orders,
	PageNameEnum.SubAccounts,
	PageNameEnum.Referrals,
	PageNameEnum.Settings,
	PageNameEnum.Security,
	PageNameEnum.History,
	PageNameEnum.Api,
];

export const SUB_ACCOUNTS_SUBMENU_PAGES = [
	PageNameEnum.Balance,
	PageNameEnum.OrderManagement,
	PageNameEnum.AccountManagement,
	PageNameEnum.ApiManagement,
	PageNameEnum.TransferHistory,
	PageNameEnum.LoginHistory,
];

export const ORDERS_SUBMENU_PAGES = [
	PageNameEnum.ActiveOrders,
	PageNameEnum.ClosedOrders,
	PageNameEnum.TradesHistory,
	PageNameEnum.Borrows,
	PageNameEnum.Repayments,
	PageNameEnum.Interests,
	PageNameEnum.Transfers,
	PageNameEnum.MarginCalls,
	PageNameEnum.Liquidations,
];
