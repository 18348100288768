import React from "react";
import styles from "styles/components/HomePage.module.scss";
import Eth from "assets/images/home_page/eth.svg";
import Btc from "assets/images/home_page/btc.svg";
/* eslint-disable */
import BackgroundImg from "assets/images/home_page/background-1.png";
import BtcOutlined from "assets/images/home_page/btc-outlined.svg";
import EthOutlined from "assets/images/home_page/eth-outlined.svg";
import EthOpaque from "assets/images/home_page/eth-opaque.svg";
import BtcOpaque from "assets/images/home_page/btc-opaque.svg";
import OutlineImg from "assets/images/home_page/outline.svg";
import classnames from "classnames";
import messages from "messages/home_page";
import common from "messages/common";
import useWindowSize from "hooks/useWindowSize";
import InternalLink from "components/InternalLink";
import { useIntl } from "react-intl";
import { useMst } from "models/Root";
import Button from "components/UI/Button";
import { Link } from "react-router-dom";

const HomePageHero: React.FC = () => (
	<>
		<HeroBackBackround />
		<div className={styles.Hero}>
			<HomePageContentHero />
		</div>
	</>
);

export default HomePageHero;

const HeroBackBackround: React.FC = () => (
	<div className={styles.Background}>
		<div className={styles.BackgroundContainer}>
			<div className={styles.Coins}>
				<div className={styles.Container}>
					<img className={classnames(styles.Coin, styles.Btc)} alt="coin" src={Btc} />
					<img
						className={classnames(styles.Coin, styles.BtcOutlined)}
						alt="coin"
						src={BtcOutlined}
					/>
					<img className={classnames(styles.Coin, styles.Eth)} alt="coin" src={Eth} />
					<img
						className={classnames(styles.Coin, styles.EthOutlined)}
						alt="coin"
						src={EthOutlined}
					/>
					<img className={classnames(styles.Coin, styles.EthOpaque)} alt="coin" src={EthOpaque} />
					<img className={classnames(styles.Coin, styles.BtcOpaque)} alt="coin" src={BtcOpaque} />
				</div>
			</div>
			<img className={classnames(styles.BackgroundImage, styles.Hero)} alt="" src={BackgroundImg} />
		</div>
	</div>
);

const HomePageContentHero: React.FC = () => {
	const { mobile } = useWindowSize();
	const {
		global: { isAuthenticated },
	} = useMst();
	const { formatMessage } = useIntl();
	return (
		<div className={classnames(styles.Content, styles.Hero)}>
			<h1 className={styles.Title}>
				{!mobile && (
					<>
						<b>
							ALP.COM -
							<img className={styles.Outline} alt="coin" src={OutlineImg} />
						</b>
						&nbsp;{formatMessage(messages.hero_title)}
					</>
				)}
				{mobile && (
					<>
						{formatMessage(messages.hero_title_mobile)}
						<br />
						<b>{formatMessage(messages.hero_title_control)}</b>
					</>
				)}
			</h1>
			<div className={styles.Text}>{formatMessage(messages.hero_subtitle)}</div>
			<div className={styles.Action}>
				{isAuthenticated ? (
					<Link to="/trade">
						<Button
							fullWidth
							variant="filled"
							color="secondary"
							iconAlign="left"
							iconCode="trade_new"
							label={formatMessage(common.standard)}
						/>
					</Link>
				) : (
					<>
						<InternalLink className={classnames(styles.Button, styles.Gradient)} to="/register">
							<span>{formatMessage(common.registerAction)}</span>
						</InternalLink>
						<InternalLink className={styles.Button} to="/login">
							<span>{formatMessage(common.login)}</span>
						</InternalLink>
					</>
				)}
			</div>
		</div>
	);
};
